<template>
  <div>
    <b-sidebar
      id="people-sidebar-right"
      :class="[
        'sidebar-people-modal',
        (isModalInfoOpen || isModalLinkPeopleOpen || isModalOptionsPeopleOpen) && device != 'mobile'
          ? '--role-openned'
          : '--role-closed'
      ]"
      right
      bg-variant="white"
      width="407px"
      v-model="isOpen"
      @hidden="closeModal"
    >
      <template #header>
        <div class="header-sidebar d-flex justify-content-between">
          <span class="sidebar-title">
            {{ $t(modalTitle) }}
          </span>

          <span
            class="close-icon"
            @click="closeModal()"
          >
            <CloseIcon />
          </span>
        </div>

        <div class="separator"></div>
      </template>
      <template>
        <div class="h-100">
          <div :class="device === 'mobile' ? 'd-flex justify-content-between' : ''">
            <div class="select-all">
              <div
                :class="['checkbox-container', allPeopleChecked ? 'checked' : '']"
                @click="checkAllPeople()"
              >
                <span class="icon-checkbox"><CheckIcon /></span>
              </div>
              <span class="text">{{ $t('Menu.Items.People') }}</span>
            </div>
            <div class="action-block">
              <div
                v-if="device !== 'mobile'"
                class="link-person"
                @click="openLinkModal()"
              >
                <LinkIcon class="link-icon" />
                <span>{{ $t('DepartmentPage.LinkPerson') }}</span>
              </div>
              <div class="function-filter">
                <span>{{ $t('DepartmentPage.Function') }}</span>
                <div
                  id="filter-select"
                  class="filter"
                  tabindex="0"
                >
                  <span>{{ $t(selectedFilter) }}</span>
                  <ArrowDown class="arrow-down" />
                </div>
              </div>
              <Popover
                :items="functionList"
                target="filter-select"
                placement="bottom"
                :custom-position="`action-button ${
                  isModalInfoOpen ? '--double-modal-openned' : ''
                }`"
                triggers="focus"
              />
            </div>
          </div>
          <div
            v-if="forceRenderPeople"
            :class="['people-list', filteredList.length >= 9 ? 'enable-scroll' : '']"
          >
            <div
              v-if="filteredList.length === 0"
              class="block-no-results"
            >
              <div class="no-results">
                <SearchIcon />
                <p class="text">{{ $t('NoResultsFound') }}</p>
              </div>
            </div>
            <div v-else>
              <div
                v-for="person in filteredList"
                :key="person.guid"
                :class="[
                  'people-row',
                  personInfo.guid === person.guid && isModalInfoOpen ? '--is-viewing' : '',
                  device === 'mobile' ? 'is-mobile' : ''
                ]"
                @click="openInfoPerson(person, $event)"
              >
                <div class="user-info-block">
                  <div
                    v-if="!person.checked"
                    class="picture"
                  >
                    <img
                      v-if="person.photo"
                      :src="person.photo"
                      alt="User photo"
                    />
                    <AvatarPlaceholder
                      v-else
                      :size="device !== 'mobile' ? '40px' : '30px'"
                      :text="picturePlaceholder(person.first_name, person.last_name)"
                    />
                  </div>
                  <div class="check-person">
                    <div
                      :class="[
                        'checkbox-container __no-action-default',
                        person.checked ? 'checked' : ''
                      ]"
                      @click="toggleCheckbox(person)"
                    >
                      <span class="icon-checkbox __no-action-default"><CheckIcon /></span>
                    </div>
                  </div>
                  <div class="text-block">
                    <div class="people-name">{{ person.first_name }} {{ person.last_name }}</div>
                    <div class="people-code">{{ person.code }}</div>
                  </div>
                </div>
                <div
                  v-if="device !== 'mobile' && isModalInfoOpen"
                  class="view-block"
                >
                  <EyeIcon />
                  <span>{{ $t('DepartmentPage.Viewing') }}</span>
                </div>
                <div
                  v-if="person.functions.length"
                  class="roles-block"
                >
                  <div class="role-tag">
                    {{ $t(person.functions[0].name) }}
                  </div>
                  <div
                    v-if="person.functions.length > 1"
                    class="role-tag"
                  >
                    + {{ person.functions.length - 1 }}
                  </div>
                </div>
                <div
                  class="unlink __no-action-default"
                  @click="unLinkPerson(person)"
                >
                  <UnLinkIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-sidebar>
    <modal-people-info
      :personInfo="personInfo"
      :device="device"
      :modalTitle="modalTitle"
      @onUnlink="(person) => unLinkPerson(person)"
      @onTransfer="(person) => transferPerson(person)"
    />
    <modal-link-people
      v-if="device !== 'mobile'"
      :device="device"
      :currentId="currentId"
      :prefix="prefix"
      @updateDragList="updateDragList"
    />
    <modal-options-people
      v-if="device !== 'mobile'"
      :device="device"
      :isToUnlink="isToUnlink"
      :isToTransfer="isToTransfer"
      :currentId="currentId"
      @onUnlinkPeople="onUnlinkPeople"
      @onTransferPeople="(id) => onTransferPeople(id)"
      @close="unCheckAllPeople"
    />
  </div>
</template>

<script>
  import { BSidebar } from 'bootstrap-vue';
  import { mapState, mapMutations } from 'vuex';
  import AvatarPlaceholder from '@/@core/components/avatar-placeholder/index.vue';
  import Popover from '@core/layouts/layout-horizontal/components/Menu/Popover.vue';
  import ModalPeopleInfo from './ModalPeopleInfo.vue';
  import ModalLinkPeople from './ModalLinkPeople.vue';
  import ModalOptionsPeople from './ModalOptionsPeople.vue';
  import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';

  export default {
    components: {
      BSidebar,
      AvatarPlaceholder,
      ModalPeopleInfo,
      ModalLinkPeople,
      Popover,
      ModalOptionsPeople,
      CloseIcon: () => import('@core/assets/icons/close-icon.svg'),
      CheckIcon: () => import('@core/assets/icons/check-icon.svg'),
      ArrowDown: () => import('@core/assets/icons/arrow-down-orange.svg'),
      LinkIcon: () => import('@core/assets/icons/link-icon.svg'),
      EyeIcon: () => import('@core/assets/icons/eye-icon.svg'),
      UnLinkIcon: () => import('@core/assets/icons/unlink-icon.svg'),
      SearchIcon: () => import('@core/assets/icons/search-icon.svg')
    },
    props: {
      modalTitle: {
        type: String,
        default: ''
      },
      currentId: {
        type: Number,
        default: null
      }
    },
    data() {
      return {
        isOpen: false,
        forceRenderPeople: true,
        allPeopleChecked: false,
        isToUnlink: false,
        isToTransfer: false,
        selectedFilter: 'DepartmentPage.All',
        prefix: localStorage.getItem('prefix'),
        filteredList: [],
        functionList: [],
        personInfo: {},
        device:
          window.innerWidth > 768
            ? 'desktop'
            : window.innerWidth > 480 && window.innerWidth <= 768
            ? 'tablet'
            : 'mobile'
      };
    },
    created() {
      window.addEventListener('resize', this.handleResize);
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize);
    },
    mounted() {
      this.UPDATE_MODAL_PEOPLE_TOGGLE(false);
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      ...mapMutations('departments', [
        'UPDATE_MODAL_PEOPLE_TOGGLE',
        'UPDATE_MODAL_INFO_TOGGLE',
        'UPDATE_MODAL_LINK_PEOPLE_TOGGLE',
        'UPDATE_PEOPLE_LIST',
        'UPDATE_MODAL_OPTIONS_TOGGLE'
      ]),
      handleResize() {
        this.device =
          window.innerWidth > 768
            ? 'desktop'
            : window.innerWidth > 480 && window.innerWidth <= 768
            ? 'tablet'
            : 'mobile';
      },
      updateFilterByFunction(func) {
        if (func === 'DepartmentPage.All') {
          this.filteredList = this.peopleList;
        } else {
          this.filteredList = this.peopleList.filter((person) =>
            person.functions.some((funcObj) => funcObj.name === func)
          );
        }
      },
      getAllFunctionsFilter() {
        this.functionList = [
          {
            title: 'DepartmentPage.All',
            click: () => {
              this.selectedFilter = 'DepartmentPage.All';
            }
          },
          ...this.rolesList.reduce((acc, e) => {
            const existingFunction = acc.find((f) => f.title === e.name);
            if (!existingFunction) {
              acc.push({
                title: e.name,
                click: () => {
                  this.selectedFilter = e.name;
                }
              });
            }
            return acc;
          }, [])
        ];
      },
      unLinkPerson(item) {
        this.isToUnlink = true;
        this.isToTransfer = false;
        this.toggleCheckbox(item);
      },
      transferPerson(item) {
        this.isToUnlink = false;
        this.isToTransfer = true;
        this.toggleCheckbox(item);
      },
      openLinkModal() {
        this.unCheckAllPeople();
        this.UPDATE_MODAL_OPTIONS_TOGGLE(false);
        this.UPDATE_MODAL_INFO_TOGGLE(false);
        this.UPDATE_MODAL_LINK_PEOPLE_TOGGLE(true);
      },
      unCheckAllPeople() {
        this.peopleList.map((e) => {
          e['checked'] = false;
        });
        this.allPeopleChecked = false;
      },
      closeModal() {
        this.UPDATE_MODAL_PEOPLE_TOGGLE(false);
        this.UPDATE_MODAL_INFO_TOGGLE(false);
        this.UPDATE_MODAL_LINK_PEOPLE_TOGGLE(false);
        this.UPDATE_MODAL_OPTIONS_TOGGLE(false);
      },
      forceRenderPeopleList() {
        this.forceRenderPeople = false;
        this.$nextTick().then(() => {
          this.forceRenderPeople = true;
        });
      },
      picturePlaceholder(first_name, last_name) {
        const firstLetter = (str) => String(str)?.[0]?.toUpperCase() ?? '';
        return `${firstLetter(first_name)}${firstLetter(last_name)}`;
      },
      toggleCheckbox(elem) {
        if (!elem.checked) {
          elem['checked'] = true;
          this.UPDATE_MODAL_INFO_TOGGLE(false);
          this.UPDATE_MODAL_LINK_PEOPLE_TOGGLE(false);
          this.UPDATE_MODAL_OPTIONS_TOGGLE(true);
        } else {
          elem['checked'] = false;
          this.allPeopleChecked = false;
          const hasChecked = this.peopleList.some((item) => !!item.checked);
          hasChecked
            ? this.UPDATE_MODAL_OPTIONS_TOGGLE(true)
            : this.UPDATE_MODAL_OPTIONS_TOGGLE(false);
        }
        this.forceRenderPeopleList();
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      },
      checkAllPeople() {
        this.filteredList.map((e) => {
          if (this.allPeopleChecked) {
            e['checked'] = false;
            this.UPDATE_MODAL_OPTIONS_TOGGLE(false);
          } else {
            e['checked'] = true;
            this.UPDATE_MODAL_OPTIONS_TOGGLE(true);
          }
        });
        this.verifyAllChecked();
        this.UPDATE_MODAL_INFO_TOGGLE(false);
        this.UPDATE_MODAL_LINK_PEOPLE_TOGGLE(false);
        this.forceRenderPeopleList();
      },
      async getPeople(id) {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        await this.$http
          .get(`/api/${this.prefix}/myconfig/department/${id}/users`)
          .then((response) => {
            this.UPDATE_PEOPLE_LIST(response.data.data);
            this.updateFilterByFunction(this.selectedFilter);
            this.forceRenderPeopleList();
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          })
          .catch((error) => console.error(error));
      },
      openInfoPerson(item, elem) {
        this.personInfo = item;
        const isNoActionDefault = elem.target.closest('.__no-action-default');
        const hasChecked = this.peopleList.some((item) => item.checked);

        if (isNoActionDefault) return;

        if (hasChecked && !item.checked) {
          item.checked = true;
          this.UPDATE_MODAL_INFO_TOGGLE(false);
        } else if (item.checked) {
          item.checked = false;
          this.allPeopleChecked = false;
          !this.peopleList.some((item) => item.checked) && this.UPDATE_MODAL_OPTIONS_TOGGLE(false);
        } else {
          this.UPDATE_MODAL_INFO_TOGGLE(true);
          this.UPDATE_MODAL_LINK_PEOPLE_TOGGLE(false);
        }
        this.forceRenderPeopleList();
      },
      verifyAllChecked() {
        this.filteredList.map((e) => {
          if (!e.checked) {
            this.allPeopleChecked = false;
          } else {
            this.allPeopleChecked = true;
          }
        });
      },
      updateDragList() {
        this.$emit('updateDragList');
      },
      async onUnlinkPeople() {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);

        const checkedPeople = this.peopleList.filter((e) => !!e.checked);
        const payload = {
          users: checkedPeople.map((e) => e.guid)
        };

        await this.$http
          .put(`/api/${this.prefix}/myconfig/department/${this.currentId}/users/remove`, payload)
          .then((response) => {
            this.UPDATE_MODAL_OPTIONS_TOGGLE(false);
            this.getPeople(this.currentId);
            this.$emit('updateDragList');
            this.showToast(
              this.$t('IntegrationPage.success'),
              'SmileIcon',
              this.$t('DepartmentPage.Disconnectedpeople'),
              'success'
            );
          })
          .catch((error) => console.error(error))
          .finally(() => this.UPDATE_FLAG_SHOW_OVERLAY(false));
      },
      async onTransferPeople(id) {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);

        const checkedPeople = this.peopleList.filter((e) => !!e.checked);
        const payload = {
          department_destiny_id: id,
          users: checkedPeople.map((e) => e.guid)
        };

        await this.$http
          .put(`/api/${this.prefix}/myconfig/department/${this.currentId}/users/transfer`, payload)
          .then((response) => {
            this.UPDATE_MODAL_OPTIONS_TOGGLE(false);
            this.getPeople(this.currentId);
            this.$emit('updateDragList');
            this.showToast(
              this.$t('IntegrationPage.success'),
              'SmileIcon',
              this.$t('DepartmentPage.TransferredPersons'),
              'success'
            );
          })
          .catch((error) => console.error(error))
          .finally(() => this.UPDATE_FLAG_SHOW_OVERLAY(false));
      }
    },
    computed: {
      ...mapState('departments', {
        isModalPeopleOpen: 'isModalPeopleOpen',
        isModalInfoOpen: 'isModalInfoOpen',
        isModalLinkPeopleOpen: 'isModalLinkPeopleOpen',
        isModalOptionsPeopleOpen: 'isModalOptionsPeopleOpen',
        peopleList: 'peopleList',
        rolesList: 'rolesList'
      })
    },
    watch: {
      isModalPeopleOpen(v) {
        this.isOpen = v;
        this.getAllFunctionsFilter();
        if (v) {
          this.selectedFilter = 'DepartmentPage.All';
          this.getPeople(this.currentId);
        } else {
          this.unCheckAllPeople();
        }
      },
      isModalOptionsPeopleOpen(v) {
        if (!v) {
          this.isToUnlink = false;
          this.isToTransfer = false;
        }
      },
      device(v) {
        if (v === 'mobile') {
          this.UPDATE_MODAL_INFO_TOGGLE(false);
          this.unCheckAllPeople();
        }
      },
      selectedFilter(v) {
        this.unCheckAllPeople();
        this.updateFilterByFunction(v);
      }
    }
  };
</script>
<style lang="scss">
  .sidebar-people-modal {
    &.--role-openned {
      .b-sidebar.b-sidebar-right {
        right: 407px;
        transition: all 0.5s ease-out;
        @media (max-width: 814px) {
          left: 0;
          width: 50vw !important;
        }
        @media (max-width: 480px) {
          left: 0;
          width: 100vw !important;
        }
      }
    }
    &.--role-closed {
      .b-sidebar.b-sidebar-right {
        right: 0;
        transition: all 0.5s ease-out;

        @media (max-width: 480px) {
          left: 0;
          width: 100vw !important;
        }
      }
    }

    .b-sidebar-header {
      display: contents;

      .header-sidebar {
        padding: 16px;

        .sidebar-title {
          display: flex;
          align-items: center;
          color: #4c4541;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;

          @media (max-width: 480px) {
            font-size: 16px;
          }
        }
      }

      .close-icon {
        border: 1px solid #974900;
        border-radius: 5px;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          width: 12px;
          height: 12px;
          path {
            fill: #974900;
          }
        }

        &:hover {
          background-color: #ffede2 !important;
        }

        &:active {
          background-color: #ffdbc4 !important;
        }
      }

      .separator {
        width: 100%;
        height: 1px;
        background: #cfc4be;
      }
    }

    .b-sidebar {
      height: 100% !important;
      .b-sidebar-body {
        padding: 16px;

        .select-all {
          display: flex;
          gap: 8px;

          .text {
            color: #4c4541;
            font-size: 16px;
            font-weight: 600;
            line-height: 26px;

            @media (max-width: 480px) {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }

        .action-block {
          display: flex;
          justify-content: space-between;
          padding: 8px;

          .link-person {
            display: flex;
            align-items: center;
            padding: 4px 14px;
            gap: 6px;
            border-radius: 5px;
            cursor: pointer;

            &:hover {
              background: #ffede2;
            }

            &:active {
              background: #ffdbc4;
            }
          }

          .link-person,
          .function-filter .filter {
            color: #974900;
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.15px;
          }

          .function-filter {
            display: flex;
            align-items: center;
            gap: 8px;
            color: #4c4541;
            font-size: 14px;
            font-weight: 600;
            line-height: 20px;

            .filter {
              display: flex;
              align-items: center;
              gap: 8px;
              padding: 4px 10px;
              border-radius: 5px;
              cursor: pointer;

              .arrow-down {
                width: 12px;
                height: 12px;
              }

              &:hover {
                background: #ffede2;
              }

              &:active {
                background: #ffdbc4;
              }
            }

            @media (max-width: 480px) {
              font-size: 12px;
              line-height: 16px;
            }
          }

          @media (max-width: 480px) {
            padding-top: 0;
          }
        }

        .people-list {
          padding-bottom: 16px;
          &.enable-scroll {
            overflow-y: auto;
            max-height: 570px;

            @media (max-width: 480px) {
              max-height: 470px;
            }
          }

          .people-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #eee;
            background: #fff;
            cursor: pointer;

            .user-info-block {
              display: flex;
              align-items: center;
              gap: 8px;
              padding: 7.5px 16px;
              overflow: hidden;

              .picture {
                img {
                  width: 40px;
                  border-radius: 50%;
                  @media (max-width: 480px) {
                    width: 30px;
                  }
                }
              }

              .check-person {
                display: none;
                padding: 11px;

                &:has(.checked) {
                  display: flex;
                }

                .checkbox-container {
                  margin: 0;
                }
              }

              .text-block {
                overflow: hidden;
                .people-name {
                  color: #4c4541;
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 20px;
                  white-space: nowrap;
                  word-break: break-all;
                  overflow: hidden;
                  text-overflow: ellipsis;

                  @media (max-width: 480px) {
                    font-size: 12px;
                    line-height: 16px;
                  }
                }
                .people-code {
                  color: #998f8a;
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 16px;

                  @media (max-width: 480px) {
                    font-size: 10px;
                    line-height: 14px;
                  }
                }
              }
            }

            .view-block {
              display: none;
              gap: 8px;
              align-items: center;
              padding-right: 16px;
              color: #974900;
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
            }

            .roles-block {
              display: flex;
              justify-content: flex-end;
              gap: 8px;
              padding-right: 16px;
              .role-tag {
                white-space: nowrap;
                border-radius: 4px;
                background: #eceff1;
                padding-inline: 9px;
                color: #607d8b;
                text-align: center;
                font-size: 12px;
                font-weight: 600;
                line-height: 16px;
              }
            }

            .unlink {
              display: none;
              cursor: pointer;
              padding: 8px;
              margin-right: 16px;
              border-radius: 5px;

              &:hover {
                background-color: #ffede2 !important;
              }

              &:active {
                background-color: #ffdbc4 !important;
              }
            }

            &.--is-viewing {
              background: #ffede2;
              border-color: #974900;
              .user-info-block {
                .text-block {
                  .people-code,
                  .people-name {
                    color: #974900;
                  }
                }
              }

              .view-block {
                display: flex;
              }

              .roles-block {
                display: none;
              }

              &.is-mobile {
                border-bottom: 1px solid #eee;
                background: #fff;
                .view-block {
                  display: none;
                }

                .roles-block {
                  display: flex;
                }
              }
            }

            &:hover,
            &:active {
              &:has(.checked) {
                border-color: #eee;
                background: #fff;
                .user-info-block {
                  .picture {
                    display: none;

                    @media (max-width: 480px) {
                      display: block;
                    }
                  }
                  .check-person {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    @media (max-width: 480px) {
                      display: none;
                    }
                  }
                  .text-block {
                    .people-name {
                      color: #4c4541;
                    }
                    .people-code {
                      color: #998f8a;
                    }
                  }
                }
                .view-block {
                  display: none;
                }
                .roles-block {
                  display: flex;
                }
                .unlink {
                  display: none;
                }
              }

              &.is-mobile {
                .view-block {
                  display: none;
                }

                .roles-block {
                  display: none;
                }
              }

              background: #ffede2;
              border-color: #974900;
              .user-info-block {
                .picture {
                  display: none;

                  @media (max-width: 480px) {
                    display: block;
                  }
                }
                .check-person {
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  @media (max-width: 480px) {
                    display: none;
                  }
                }
                .text-block {
                  .people-code,
                  .people-name {
                    color: #974900;
                  }
                }
              }
              .view-block {
                display: none;
              }
              .roles-block {
                display: none;
              }
              .unlink {
                display: flex;

                @media (max-width: 480px) {
                  display: none;
                }
              }
            }
          }

          .block-no-results {
            padding-block: 16px;
            .no-results {
              height: calc(100vh - 200px);
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 16px;
              border-radius: 6px;
              border: 1px solid #cfc4be;

              svg {
                width: 16px;
                height: 16px;
                fill: #998f8a;
              }

              .text {
                margin: 0;
                color: #998f8a;
                font-size: 16px;
                font-weight: 600;
                line-height: 26px;
              }

              @media (max-width: 480px) {
                svg {
                  width: 12px;
                  height: 12px;
                }
                .text {
                  font-size: 12px;
                  line-height: 16px;
                }
              }
            }
          }

          &::-webkit-scrollbar {
            width: 7px;
          }

          &::-webkit-scrollbar-track {
            background: #fff;
          }

          &::-webkit-scrollbar-thumb {
            background-color: #cfc4be;
            border-radius: 9px;
          }
        }

        .checkbox-container {
          display: flex;
          background-color: #fff;
          border: 1px solid #cfc4be;
          border-radius: 3px;
          margin-top: 4px;
          cursor: pointer;
          width: 18px;
          height: 18px;

          .icon-checkbox {
            display: flex !important;
            align-items: center;
            justify-content: center;
            margin-top: 2px;
            svg {
              width: 12px;
              height: 12px;
            }
          }
          &.checked {
            background-color: #974900;
            display: block;
          }

          @media (max-width: 480px) {
            display: none;
          }
        }

        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-track {
          background: #fff;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #cfc4be;
          border-radius: 9px;
        }
      }
    }
  }
</style>
