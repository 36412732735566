<template>
  <div id="departments-page">
    <modal-delete
      :level="currentLevel"
      :name="currentName"
      @confirm="deleteDepartment"
    />
    <modal-add-edit
      v-if="device !== 'mobile'"
      :modalTitle="modalTitle"
      :responsibleList="usersList"
      :currentId="currentId"
      @clearEditInfos="clearEditInfos()"
      @closeModal="closeModalAddEdit()"
      @addedItem="getDepartments()"
    />
    <modal-people
      :modalTitle="modalPeopleName"
      :currentId="currentId"
      @updateDragList="updateDragList"
    />
    <div class="header-block">
      <div class="first-line">
        <div class="title">
          {{ $t('PagesViews.Departments') }}
        </div>
        <div
          v-if="device !== 'mobile'"
          class="add-department"
        >
          <b-button
            class="btn-new-department"
            @click="changeModalName('new')"
          >
            <AddIcon class="add-icon" />
            {{ $t('DepartmentPage.NewDepartment') }}
          </b-button>
        </div>
      </div>
      <div class="second-line">
        <div class="search-input">
          <div class="div-custom-input input__departments">
            <div class="icon-block">
              <SearchIcon class="search-icon" />
            </div>
            <b-form-tags
              class="input-search-departments"
              ref="formTagDepartments"
              v-model="filter.textFilter"
              duplicate-tag-text=""
              :placeholder="filter.textFilter.length === 0 ? $t('SearchPlaceholderDefault') : ''"
              @input="getDepartments()"
              remove-on-delete
            />
          </div>
        </div>
      </div>
    </div>
    <div class="body-block">
      <div
        v-if="dragList.length === 0 && !noItemsFoundes"
        class="h-100"
      >
        <div class="no-items h-100">
          <EmptyBoxIcon class="empty-box" />
          <p class="title">{{ $t('DepartmentPage.NoDepartmentsTitle') }}</p>
          <p
            v-if="device !== 'mobile'"
            class="subtitle"
          >
            {{ $t('DepartmentPage.NoDepartmentsSubtitle') }}
          </p>
          <b-button
            v-if="device !== 'mobile'"
            class="btn-new-department"
            @click="changeModalName('new')"
          >
            <AddIcon class="add-icon" />
            {{ $t('DepartmentPage.NewDepartment') }}
          </b-button>
        </div>
      </div>
      <CollapseDraggable
        v-else
        :list="dragList"
        :dragList="dragList"
        :dragListStart="dragListStart"
        :optionIcons="optionIcons"
        :levelIcons="levelIcons"
        :selectedChecks="selectedChecks"
        :rolesList="rolesList"
        :responsibleList="usersList"
        :shouldUpdate="shouldUpdate"
        :forceRender="forceRender"
        @SelectedToZero="UPDATE_SELECTED_CHECKS(0)"
        @deleteDepartments="(ids) => deleteDepartment(ids)"
        @updatedList="updatedList"
        @getEditInfos="(id, level) => getEditInfos(id, level)"
        @checkedCounter="checkedCounter"
        @undoChangesTree="undoChangesTree"
        @saveActualTree="saveActualTree"
      />
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import { BFormTags, BButton } from 'bootstrap-vue';
  import ModalAddEdit from './components/ModalAddEdit.vue';
  import ModalDelete from './components/ModalDelete.vue';
  import ModalPeople from './components/ModalPeople.vue';
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
  import CollapseDraggable from '@/@core/components/collapse-draggable/CollapseDraggable.vue';
  import DepartmentIcon from '@core/assets/icons/department-icon.svg';
  import SubDepartmentIcon from '@core/assets/icons/subdepartment-icon.svg';
  import SearchIcon from '@core/assets/icons/search-icon.svg';
  import AddIcon from '@core/assets/icons/add-icon.svg';
  import NewIcon from '@core/assets/icons/new-icon.svg';
  import InfoIcon from '@core/assets/icons/info-outline-icon.svg';
  import PeopleIcon from '@core/assets/icons/people-icon.svg';
  import OptionsIcon from '@core/assets/icons/options-icon.svg';
  import DeleteIcon from '@core/assets/icons/delete-icon.svg';
  import EditIcon from '@core/assets/icons/edit-icon.svg';
  import EmptyBoxIcon from '@core/assets/icons/empty-box-icon.svg';

  export default {
    components: {
      BFormTags,
      BButton,
      ToastificationContent,
      CollapseDraggable,
      SearchIcon,
      ModalAddEdit,
      ModalPeople,
      ModalDelete,
      AddIcon,
      NewIcon,
      InfoIcon,
      PeopleIcon,
      EmptyBoxIcon,
      OptionsIcon,
      DeleteIcon,
      EditIcon
    },
    data() {
      return {
        filter: {
          textFilter: []
        },
        noItemsFoundes: false,
        usersList: [],
        allCheckedItems: [],
        currentLevel: null,
        currentId: null,
        currentName: null,
        forceRender: 0,
        shouldUpdate: false,
        deleteText: '',
        modalPeopleName: '',
        prefix: localStorage.getItem('prefix'),
        device:
          window.innerWidth > 768
            ? 'desktop'
            : window.innerWidth > 480 && window.innerWidth <= 768
            ? 'tablet'
            : 'mobile',
        levelIcons: [
          {
            title: 'DepartmentPage.Department',
            techName: 'Department',
            level: 1,
            icon: DepartmentIcon
          },
          {
            title: 'DepartmentPage.Subdepartment',
            techName: 'Subdepartment',
            level: 2,
            icon: SubDepartmentIcon
          }
        ],
        optionIcons: [
          {
            title: 'DepartmentPage.People',
            icon: PeopleIcon,
            click: (el) => {
              this.currentId = el.id;
              this.UPDATE_MODAL_PEOPLE_TOGGLE(true);
              this.modalPeopleName = el.name;
            }
          },
          {
            title: 'Info',
            icon: InfoIcon,
            click: () => {}
          },
          {
            title: 'DepartmentPage.Actions',
            icon: OptionsIcon,
            removeOnMobile: true,
            click: () => {},
            actions: [
              {
                title: 'DepartmentPage.New',
                icon: NewIcon,
                click: (e) => {
                  const editTitle = `${this.$t(e.title)} ${this.$t(
                    'DepartmentPage.Subdepartment'
                  )}`;
                  this.UPDATE_MODAL_TITLE(editTitle);
                  this.UPDATE_MODAL_ADD_EDIT_TOGGLE(true);
                }
              },
              {
                title: 'DepartmentPage.Edit',
                icon: EditIcon,
                click: (e) => {
                  const depOrSub =
                    this.currentLevel == 1
                      ? 'DepartmentPage.Department'
                      : 'DepartmentPage.Subdepartment';
                  const editTitle = `${this.$t(e.title)} ${this.$t(depOrSub)}`;
                  this.UPDATE_MODAL_TITLE(editTitle);
                  this.UPDATE_MODAL_ADD_EDIT_TOGGLE(true);
                }
              },
              {
                title: 'DepartmentPage.Delete',
                icon: DeleteIcon,
                click: () => {
                  this.currentName = this.getCurrentName(this.dragList);
                  this.UPDATE_MODAL_DELETE(true);
                }
              }
            ]
          }
        ]
      };
    },
    created() {
      window.addEventListener('resize', this.handleResize);
    },
    destroyed() {
      window.removeEventListener('resize', this.handleResize);
    },
    async mounted() {
      const vm = this;
      await this.$http
        .get(`/api/${vm.prefix}/myconfig/department/context`)
        .then((response) => {
          this.usersList = response.data.data.users.map((item) => ({
            value: item.guid,
            text: item.name
          }));
          this.UPDATE_ROLES_LIST(
            response.data.data.functions.map((item) => ({
              ...item,
              name: item.name
            }))
          );
        })
        .catch((error) => console.error(error));
      this.getDepartments();
      $(function () {
        document
          .getElementsByClassName('input-search-departments')[0]
          .setAttribute('tabIndex', '-1');

        const slider = document.querySelector('.b-form-tags-list');
        let mouseDown = false;
        let startX, scrollLeft;

        let startDragging = function (e) {
          mouseDown = true;
          startX = e.pageX - slider.offsetLeft;
          scrollLeft = slider.scrollLeft;
        };
        let stopDragging = function () {
          mouseDown = false;
        };

        slider.addEventListener('mousemove', (e) => {
          e.preventDefault();
          if (!mouseDown) {
            return;
          }
          const x = e.pageX - slider.offsetLeft;
          const scroll = x - startX;
          slider.scrollLeft = scrollLeft - scroll;
        });

        slider.addEventListener('mousedown', startDragging, false);
        slider.addEventListener('mouseup', stopDragging, false);
        slider.addEventListener('mouseleave', stopDragging, false);

        $('.b-form-tags-input').blur((event) => {
          let input = vm.$refs.formTagDepartments;
          input.addTag(event.target.value);
        });
      });
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      ...mapMutations('departments', [
        'UPDATE_MODAL_TITLE',
        'UPDATE_MODAL_ADD_EDIT',
        'UPDATE_MODAL_ADD_EDIT_TOGGLE',
        'UPDATE_MODAL_PEOPLE_TOGGLE',
        'UPDATE_DRAG_LIST',
        'UPDATE_DRAG_LIST_START',
        'UPDATE_SELECTED_CHECKS',
        'UPDATE_ROLES_LIST',
        'UPDATE_MODAL_DELETE',
        'UPDATE_MODAL_DELETE_SNACK_BAR'
      ]),
      handleResize() {
        this.device =
          window.innerWidth > 768
            ? 'desktop'
            : window.innerWidth > 480 && window.innerWidth <= 768
            ? 'tablet'
            : 'mobile';
      },
      updateDragList() {
        this.getDepartments();
      },
      closeModalAddEdit() {
        this.UPDATE_MODAL_ADD_EDIT_TOGGLE(false);
      },
      async getDepartments() {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        const textFilterQuery = this.filter.textFilter
          .map((filter) => `${filter}`)
          .join('&text_filter=');
        await this.$http
          .get(`/api/${this.prefix}/myconfig/department?text_filter=${textFilterQuery}`)
          .then((response) => {
            const updatedArr = response.data.data;
            textFilterQuery !== '' ? (this.noItemsFoundes = true) : (this.noItemsFoundes = false);
            this.addLevelByChild(updatedArr);
            this.UPDATE_DRAG_LIST(updatedArr);
            this.saveActualTree();
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          });
      },
      addLevelByChild(arr, level = 1) {
        arr.map((e) => {
          e.level = level;
          if (e.child.length > 0) {
            e.child.forEach((f) => (f.parentId = e.id));
          }
          if (e.child.length) this.addLevelByChild(e.child, level + 1);
        });
      },
      getEditInfos(id, level) {
        this.currentId = id;
        this.currentLevel = level;
      },
      clearEditInfos() {
        this.currentId = null;
        this.currentLevel = null;
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      },
      async updatedList(list, id) {
        this.allCheckedItems = [];
        this.getAllCheckedItems(list);
        this.UPDATE_FLAG_SHOW_OVERLAY(true);

        const departmentId = this.findParentId(list, Number(id));
        const payload = {
          child: this.allCheckedItems.length ? this.allCheckedItems : [Number(id)]
        };

        await this.$http
          .put(`/api/${this.prefix}/myconfig/department/${departmentId}/movimentation`, payload)
          .then(() => {
            this.unCheckAll(list);
            this.UPDATE_DRAG_LIST(list);
            this.forceRender = this.forceRender + 1;
          })
          .catch((error) => {
            error.response.data.errors.map((err) => {
              this.showToast(this.$t('Error'), 'FrownIcon', err.message, 'danger');
            });
          })
          .finally(() => {
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          });
      },
      findParentId(array, targetId) {
        for (const obj of array) {
          if (obj.child && obj.child.some((child) => child.id === targetId)) {
            return obj.id;
          }
          const nestedParentId = this.findParentId(obj.child || [], targetId);
          if (nestedParentId) {
            return nestedParentId;
          }
        }
        return null;
      },
      unCheckAll(list) {
        for (const obj of list) {
          obj['checked'] = false;
          if (obj.child && obj.child.length > 0) {
            this.unCheckAll(obj.child);
          }
        }
        this.UPDATE_SELECTED_CHECKS(0);
      },
      getAllCheckedItems(list) {
        list.map((e) => {
          if (e.checked) {
            this.allCheckedItems.push(e.id);
          }
          if (e.child) this.getAllCheckedItems(e.child);
        });
      },
      getCurrentName(list) {
        if (!list || list.length === 0) {
          return null;
        }

        for (const item of list) {
          if (item.id === this.currentId) {
            return item.name;
          }

          if (item.child && item.child.length > 0) {
            const foundItem = this.getCurrentName(item.child);
            if (foundItem) {
              return foundItem;
            }
          }
        }

        return null;
      },

      async deleteDepartment(ids) {
        this.UPDATE_FLAG_SHOW_OVERLAY(true);
        const payload = {
          departments_ids: ids ? ids : [this.currentId]
        };
        await this.$http
          .delete(`/api/${this.prefix}/myconfig/department`, { data: payload })
          .then(() => {
            if (ids) {
              this.showToast(
                this.$t('IntegrationPage.success'),
                'SmileIcon',
                this.$t('DepartmentPage.DeletedItems'),
                'success'
              );
            } else {
              if (this.currentLevel == 1) {
                this.showToast(
                  this.$t('IntegrationPage.success'),
                  'SmileIcon',
                  this.$t('DepartmentPage.DeletedDepartment'),
                  'success'
                );
              } else {
                this.showToast(
                  this.$t('IntegrationPage.success'),
                  'SmileIcon',
                  this.$t('DepartmentPage.DeletedSubdepartment'),
                  'success'
                );
              }
            }

            this.shouldUpdate = !this.shouldUpdate;
            this.UPDATE_MODAL_DELETE(false);
            this.UPDATE_MODAL_DELETE_SNACK_BAR(false);
            this.UPDATE_SELECTED_CHECKS(0);
            this.getDepartments();
          })
          .catch((error) => {
            this.showToast(this.$t('Error'), 'FrownIcon', error.response.data.message, 'danger');
          })
          .finally(() => {
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
          });
      },
      checkedCounter(counter) {
        this.UPDATE_SELECTED_CHECKS(counter);
      },
      undoChangesTree() {
        this.showToast(
          this.$t('Error'),
          'FrownIcon',
          this.$t('DepartmentPage.ErrorToastText'),
          'danger'
        );
        this.UPDATE_DRAG_LIST(JSON.parse(JSON.stringify(this.dragListStart)));
      },
      saveActualTree() {
        this.UPDATE_DRAG_LIST_START(JSON.parse(JSON.stringify(this.dragList)));
      },
      changeModalName(name) {
        this.UPDATE_MODAL_ADD_EDIT_TOGGLE(true);
        if (name === 'new') {
          this.UPDATE_MODAL_TITLE('DepartmentPage.NewDepartment');
          this.currentId = null;
        }
      }
    },
    computed: {
      ...mapState('departments', {
        modalTitle: 'modalTitle',
        modalAddEdit: 'modalAddEdit',
        isModalAddEditOpen: 'isModalAddEditOpen',
        rolesList: 'rolesList',
        dragList: 'dragList',
        dragListStart: 'dragListStart',
        selectedChecks: 'selectedChecks'
      })
    }
  };
</script>
<style lang="scss">
  #departments-page {
    padding: 16px 0;
    border-radius: 6px;
    background: #fff;
    box-shadow: 0px 4px 24px 0px rgba(58, 46, 38, 0.1);
    @media (max-width: 768px) {
      margin-top: 24px;
    }
    .header-block {
      padding: 0 16px;
      .first-line {
        display: flex;
        justify-content: space-between;
        .title {
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
        }
        .add-department {
          .btn-new-department {
            border-color: #974900 !important;
            background-color: #974900 !important;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            width: 100% !important;
            min-width: 136px;
            padding: 3px 14px !important;
            font-size: 12px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.0125em;

            .add-icon {
              width: 12px;
              height: 12px;
              fill: #fff;
            }
          }
        }
      }
      .second-line {
        .search-input {
          display: flex;
          align-items: center;
          padding: 16px 0 8px;

          .div-custom-input {
            display: flex;
            align-items: center;
            border-radius: 4px;
            width: 100%;
            overflow: hidden;
            border: 1px solid #cfc4be;
            .icon-block {
              display: flex;
              align-items: center;
              padding-left: 14px;
              height: 14px;
              .search-icon {
                width: 14px;
                height: 14px;
                fill: #cfc4be;
              }
            }
            .input-search-departments {
              width: 100% !important;
              border: none;
              overflow: hidden;

              &.focus {
                box-shadow: none;
              }
              &.b-form-tags {
                padding: 0 !important;
                display: flex;
                align-items: center !important;
                .b-form-tags-list {
                  width: 100% !important;
                  margin-left: 14px !important;
                  margin-top: 0;
                  height: 100% !important;
                  flex-wrap: nowrap !important;
                  display: flex !important;
                  align-items: center !important;
                  overflow: scroll;
                  overflow-y: hidden;
                  cursor: grabbing;
                  cursor: -webkit-grabbing;

                  &::-webkit-scrollbar {
                    display: none !important;
                  }
                  .b-form-tags-field {
                    margin: 9px 0;
                  }
                }
              }

              .b-form-tags-button {
                display: none;
              }

              .b-form-tag {
                background-color: #974900;
                margin-right: 0.6rem;
                padding: 2px 8px !important;
                font-size: 13px;
              }
            }
          }
        }
      }
    }
    .body-block {
      .no-items {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        margin: 0 16px;
        border: 1px solid #cfc4be;

        .empty-box {
          width: 80px;
          height: 80px;
        }

        .title {
          font-size: 16px;
          font-weight: 600;
          line-height: 26px;
          color: #4c4541;
          margin: 0;
        }

        .subtitle {
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: #998f8a;
          padding: 4px 0 12px;
          margin: 0;
        }

        .btn-new-department {
          border-color: #974900 !important;
          background-color: #974900 !important;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;
          padding: 8px 21px;
          line-height: 20px;
          letter-spacing: 0.175px;

          .add-icon {
            width: 14px;
            height: 14px;
            fill: #fff;
          }
        }

        @media (max-width: 480px) {
          .empty-box {
            width: 40px;
            height: 40px;
          }

          .title {
            font-size: 14px;
          }

          .subtitle {
            font-size: 12px;
            text-align: center;
            padding: 4px 48px 12px;
            line-height: 16px;
          }

          .btn-new-department {
            padding: 4px 14px;
          }
        }
      }

      &:has(.block-no-results) {
        height: calc(100% - 90px);
      }
      &:has(.no-items) {
        height: calc(100% - 90px);
      }
    }
    &:has(.body-block .block-no-results) {
      height: calc(100vh - 135px);
    }
    &:has(.body-block .no-items) {
      height: calc(100vh - 135px);
    }
  }
</style>
